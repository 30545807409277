import React, { useEffect, useState } from "react";
import { Button, Layout } from "antd";
import { ModalCart } from "../../components/modal/cart";
// import CartService from "../../../service/Cart.service";
import { Authenticate } from "../../service/Authenticate.service";

export default function Footer({ Summaryprice }) {
  const { Footer: AntFooter } = Layout;
  const authService = Authenticate();
  // const ctservice = CartService();
  const [ModalCartOpen, setModalCartOpen] = useState(false);
 
  return (
    <AntFooter  >
      <div class="footerecom" >
      <Button onClick={() => setModalCartOpen(true)} style={{ backgroundColor: "#fa3f3f",color: "#FFF", borderRadius: 45,width:150}}>ชำระเงิน</Button> 
      <span style={{fontWeight: "bold",paddingLeft: 15,fontSize: 16}}>{Summaryprice} บาท</span>
      </div>
      {ModalCartOpen && (
          <ModalCart
            show={ModalCartOpen}
            close={() => setModalCartOpen(false)}
            cuscode={authService.getUserId() }
          ></ModalCart>
        )}
    </AntFooter>
  );
}

