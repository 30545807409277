import React from 'react'
import { Route } from 'react-router-dom'

import { PickupListPrint} from '../components/print';
import { DeliveryNotePrint} from '../components/print';

export const PrintRouter = (<>
  <Route path="/pickup-list-print/:code" element={<PickupListPrint />} />
  <Route path="/delivery-note/:code" element={<DeliveryNotePrint />} />
  {/* <Route path="/quotation-print/:code/:print?" element={<QuotationPrint />} />
  <Route path="/repair-order-print/:code/:print?" element={<RepairOrderPrint />} />
  <Route path="/receipt-print/:code/:print?" element={<ReceiptsPrint />} /> */}
</>)