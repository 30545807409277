import { PlusOutlined } from "@ant-design/icons";
const ButtonUploadImage = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

export default ButtonUploadImage