import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Home from "../pages/Home";
import Login from "../pages/Login";
import PageNotFound from "../pages/404";
import PrivateRoute from "../components/auth/PrivateRoutes";
import EcommerceRoutes from "../components/auth/EcommerceRoutes";
import { DashBoard } from "../pages/dashboard";
import { ROLES } from "../constant/constant";

import { DataRouter } from "./data.router";
import { WarehouseRouter } from "./warehouse.router";
import { HeaderEcommerce } from "./ecommerce.router";
import { PrintRouter } from "./print.route";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/login" element={<Login />} />

        <Route
          element={<EcommerceRoutes allowdRole={[ROLES.ADMIN, ROLES.USER]} />}
        >
          {HeaderEcommerce}
        </Route>
        <Route
          element={<PrivateRoute allowdRole={[ROLES.ADMIN, ROLES.USER]} />}
        >
          <Route path="/dashboard" element={<DashBoard />} />
        </Route>

        <Route element={<PrivateRoute allowdRole={[ROLES.ADMIN]} />}>
          {DataRouter}
          {WarehouseRouter}
        </Route>

        <Route
          element={
            <PrivateRoute allowdRole={[ROLES.ADMIN, ROLES.USER]} mode="print" />
          }
        >
          {PrintRouter}
        </Route>
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
