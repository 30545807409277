/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Space,
  Select,
  Image,
  Flex,
  Button,
  Collapse,
  message,
  Badge,
} from "antd";
import { Input } from "antd";
import CartService from "../../service/Cart.service";
import OptionService from "../../service/Options.service";
import { Authenticate } from "../../service/Authenticate.service";
import { BACKEND_URL_MAIN } from "../../utils/util";
// import { ModalCart } from "../../components/modal/cart";
import { ModalMenuItem } from "../../components/modal/menuitem/index";
import Footer from "../../components/layout/Footer";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
const EcommerceAccess = () => {
  // const [openProduct, setOpenProduct] = useState(false);
  const ctservice = CartService();
  const opservice = OptionService();
  const authService = Authenticate();
  const [form] = Form.useForm();
  const { Meta } = Card;
  const [optionType, setOptionType] = useState([]);
  const [AllProductEcommerce, setAllProductEcommerce] = useState([]);
  const [ModalMenuOpen, setModalMenuOpen] = useState(false);
  const [selectstcode, setSelectstcode] = useState(null);
  const [totalprice, setTotalprice] = useState(null);

  useEffect(() => {
    const initial = async () => {
      handleSearch();
      GetItemsType();
      handleSummaryPrice();
    };

    initial();
    return () => {};
  }, []);

  useEffect(() => {
    if (AllProductEcommerce) handleSummaryPrice();
  }, [AllProductEcommerce]);

  const GetItemsType = () => {
    opservice.optionsItemstype().then((res) => {
      let { data } = res.data;
      setOptionType(data);
    });
  };

  const handleSummaryPrice = () => {
    const newData = [...AllProductEcommerce];

    const total_price = newData.reduce(
      (a, v) => (a += Number(v.qty || 0) * Number(v?.price || 0)),
      0
    );

    setTotalprice(total_price);
  };

  const handleSearch = () => {
    form.validateFields().then((v) => {
      let para = { ...v, cuscode: authService.getUserId() };

      ctservice
        .searchByCustomer(para)
        .then((res) => {
          let { data } = res.data;
          data.forEach((array) => {
            // วนลูปผ่านทุกๆ อ็อบเจกต์ในอาร์เรย์ซ้อนอยู่
            array.file.forEach((obj) => {
              // ทำการแก้ไขอ็อบเจกต์ที่อยู่ภายใน
              // console.log(obj);
              obj.file_name = `${BACKEND_URL_MAIN}/uploads/` + obj.file_name;
            });
          });
          setAllProductEcommerce(data);
        })
        .catch((err) => {});
      // console.log(AllProductEcommerce)
    });
  };

  const handleManualAmount = (action, stcode) => {
    const tmp = [...AllProductEcommerce];
    const selectList = tmp.find((a) => a.stcode === stcode);

    if (selectList.qty > 0 || action === "add") {
      if (action === "add") selectList.qty = parseInt(selectList.qty) + 1;
      else selectList.qty = parseInt(selectList.qty) - 1;

      const parm = {
        stcode: stcode,
        qty: selectList.qty,
        cuscode: authService.getUserId(),
        unit: selectList.unit,
        price: selectList.price,
      };

      ctservice
        .update(parm)
        .then((r) => {})
        .catch((err) => {
          message.error("เพิ่มไม่สำเร็จ");
          console.warn(err);
        });

      setAllProductEcommerce(tmp);
    }
  };

  const handleClear = () => {
    form.resetFields();

    handleSearch();
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const showModalMenu = (stcode) => {
    // alert(stcode)
    setSelectstcode(stcode);
    setModalMenuOpen(true);
  };

  return (
    <>
      <div>
        <Space
          direction="vertical"
          size="middle"
          style={{ display: "flex", position: "relative", paddingTop: 20 }}
        >
          <Row gutter={[24, 24]} className="m-0">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Collapse
                items={[
                  {
                    key: "search",
                    label: (
                      <>
                        <SearchOutlined />
                        <span> ค้นหา</span>
                      </>
                    ),
                    children: (
                      <Form form={form} layout="vertical" autoComplete="off">
                        <Row gutter={[8, 8]} className="m-0">
                          <Col xs={12} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item name="stname">
                              <Input placeholder="ใส่ชื่อสินค้า" />
                            </Form.Item>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={8} xl={6}>
                            <Form.Item name="typecode">
                              <Select
                                size="large"
                                showSearch
                                filterOption={filterOption}
                                options={optionType.map((item) => ({
                                  value: item.typecode,
                                  label: item.typename,
                                }))}
                                placeholder="เลือกหมวดสินค้า"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={8} md={12} lg={12} xl={12}>
                            <Flex justify="flex-end" gap={8}>
                              <Button
                                type="primary"
                                size="small"
                                className="bn-action"
                                icon={<SearchOutlined />}
                                onClick={() => handleSearch()}
                              >
                                ค้นหา
                              </Button>
                              <Button
                                type="primary"
                                size="small"
                                className="bn-action"
                                danger
                                icon={<ClearOutlined />}
                                onClick={() => handleClear()}
                              >
                                ล้าง
                              </Button>
                            </Flex>
                          </Col>
                        </Row>
                      </Form>
                    ),
                  },
                ]}
              />
            </Col>
            {AllProductEcommerce.map((list, ind) => (
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <Badge.Ribbon
                  text="สินค้าแนะนำ"
                  color="red"
                  style={
                    list.pin === "Y"
                      ? { display: "inline" }
                      : { display: "none" }
                  }
                >
                  <Card style={{ borderWidth: 2 }}>
                    <Row gutter={[24, 24]} className="m-0">
                      <Card
                        onClick={() => showModalMenu(list.stcode)}
                        style={{ width: "100%" }}
                        hoverable
                      >
                        <Row gutter={[24, 24]} className="m-0">
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            {!!list.file[0] ? (
                              list.file.map((file) => (
                                <div key={file.img_id}>
                                  <Image
                                    preview={false}
                                    width={55}
                                    height={50}
                                    alt={`Image ${file.name}`}
                                    src={file.file_name}
                                  />
                                </div>
                              ))
                            ) : (
                              <div key={list[ind]}>
                                <Image
                                  preview={false}
                                  width={55}
                                  height={50}
                                  alt={`No Image`}
                                  src={`${BACKEND_URL_MAIN}/logo_nsf.jpg`}
                                />
                              </div>
                            )}
                          </Col>
                          <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                            <Meta
                              title={list.stcode + " " + list.stname}
                              description={list.unit}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Row>
                    <Row gutter={[24, 24]} className="mt-2">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            // paddingLeft: 15,
                          }}
                        >
                          {list.price + " บาท"}
                        </span>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        // style={{ paddingTop: 25, paddingLeft: 13 }}
                      >
                        <div class="col-12 col-md-6  float-right">
                          <Button
                            style={{
                              width: 20,
                              height: 30,
                              backgroundColor: "#fa3f3f",
                            }}
                            onClick={() =>
                              handleManualAmount("delete", list.stcode)
                            }
                            shape="circle"
                            icon={
                              <MinusOutlined
                                style={{
                                  color: "#FFFF",
                                }}
                              />
                            }
                          />
                          <span
                            style={{
                              fontWeight: "bold",
                              padding: 6,
                              fontSize: 13,
                            }}
                          >
                            {list.qty}
                            {/* 0 */}
                          </span>
                          <Button
                            style={{
                              width: 20,
                              height: 30,
                              backgroundColor: "#fa3f3f",
                            }}
                            onClick={() =>
                              handleManualAmount("add", list.stcode)
                            }
                            shape="circle"
                            icon={
                              <PlusOutlined
                                style={{
                                  color: "#FFFF",
                                }}
                              />
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Badge.Ribbon>
              </Col>
            ))}
          </Row>
          <br></br>
          <br></br>
          <Footer Summaryprice={totalprice} />
        </Space>

        {ModalMenuOpen && (
          <ModalMenuItem
            show={ModalMenuOpen}
            close={() => setModalMenuOpen(false)}
            stcode={selectstcode}
            cuscode={authService.getUserId()}
          ></ModalMenuItem>
        )}
        {/* {ModalCartOpen && (
          <ModalCart
            show={ModalCartOpen}
            close={() => setModalCartOpen(false)}
            cuscode={userInfo.userid}
          ></ModalCart>
        )} */}
      </div>
    </>
  );
};

export default EcommerceAccess;
