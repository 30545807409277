import React, { useState, useEffect } from "react";
import { Row, Col, ConfigProvider, Card, Table, message } from "antd";
import { showdataso } from "./model";
import SOService from "../../service/SO.service";
import { Authenticate } from "../../service/Authenticate.service";
import { ModalHistory } from "../../../src/components/modal/historySO";
const soservice = SOService();
const authService = Authenticate();

const CarteAccess = () => {
  const [accessData, setAccessData] = useState([]);
  const [ModalHistoryOpen, setModalHistoryOpen] = useState(false);
  const [soCode, setSOCode] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const EditData = async (data) => {
    setSOCode(data);
    setModalHistoryOpen(true);
    // console.log(data)
  };

  useEffect(() => {
    const users = authService.getUserInfo();
    setUserInfo(users);
    const initial = async () => {
      getData();

      // console.log(userInfo.userid)
    };

    initial();
    return () => {};
  }, []);

  const getData = () => {
    // console.log(userInfo)
    const { userid } = authService.getUserInfo();
    soservice
      .getbycus(userid)
      .then((res) => {
        const { data } = res.data;
        // console.log(data)
        setAccessData(data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Request error!");
      });
  };

  const column = showdataso({ EditData });
  return (
    <>
      <div style={{ paddingTop: 15 }}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#212F3D",
            },
          }}
        >
          <Row gutter={[24, 24]} className="m-0">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card
                title="ประวัติการสั่งซื้อ"
                headStyle={{
                  backgroundColor: "#2c3e50",
                  color: "#FFF",
                }}
                align="center"
                bordered={false}
              >
                <Table
                  columns={column}
                  dataSource={accessData}
                  pagination={false}
                  size="small"
                  rowKey="socode"
                ></Table>
              </Card>
            </Col>
          </Row>
        </ConfigProvider>
        {ModalHistoryOpen && (
          <ModalHistory
            show={ModalHistoryOpen}
            close={() => setModalHistoryOpen(false)}
            socode={soCode}
          ></ModalHistory>
        )}
      </div>
    </>
  );
};

export default CarteAccess;
