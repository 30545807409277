import React, { useState, useEffect } from "react";
import {
  Modal,
  Card,
  Table,
  message,
  Form,
  Spin,
  DatePicker,
  Flex,
  Button,
} from "antd";
import { Row, Col, Space } from "antd";
import { Input, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { DEFALUT_CHECK_CART } from "./model";
import { formatMoney } from "../../../utils/util";
import { RiDeleteBin5Line } from "react-icons/ri";
import dayjs from "dayjs";
// import { ModalSupplierManage } from './modal-cart.js';
import { columnsParametersEditable, componentsEditable } from "./model";
import CartService from "../../../service/Cart.service";
import SOService from "../../../service/SO.service";
const soservice = SOService();
const ctservice = CartService();
const dateFormat = "DD/MM/YYYY";

export default function ModaHistory({ show, close, socode }) {
  const [form] = useForm();
  const [listDetail, setlistDetail] = useState([]);
  const [formDetail, setFormDetail] = useState(DEFALUT_CHECK_CART);
  const [openModal, setOpenModel] = useState(show);
  const [loading, setLoading] = useState(true);

  /** handle logic component */
  const handleClose = async () => {
    setTimeout(() => {
      close(false);
    }, 140);

    //setTimeout( () => close(false), 200 );
  };

  const handleEditCell = (row) => {
    const newData = (r) => {
      const itemDetail = [...listDetail];
      const newData = [...itemDetail];

      const ind = newData.findIndex((item) => r?.stcode === item?.stcode);
      if (ind < 0) return itemDetail;
      const item = newData[ind];
      newData.splice(ind, 1, {
        ...item,
        ...row,
      });

      handleSummaryPrice();
      return newData;
    };
    setlistDetail([...newData(row)]);
  };

  const handleDelete = (data) => {
    ctservice
      .deleted(data)
      .then((_) => {
        const itemDetail = [...listDetail];
        // console.log(listDetail)
        const tmp = itemDetail.filter((item) => item.cart_id !== data);
        // console.log(tmp)
        setlistDetail([...tmp]);
      })
      .catch((err) => {
        console.log(err);
        message.error("Request error!");
      });
  };

  const handleRemove = (record) => {
    // console.log(record)

    const itemDetail = [...listDetail];
    return itemDetail.length >= 1 ? (
      <Button
        className="bt-icon"
        size="small"
        danger
        icon={
          <RiDeleteBin5Line style={{ fontSize: "1rem", marginTop: "3px" }} />
        }
        onClick={() => handleDelete(record?.cart_id)}
      />
    ) : null;
  };

  /** setting initial component */
  const column = columnsParametersEditable(handleEditCell, { handleRemove });

  const handleConfirm = () => {
    form
      .validateFields()
      .then((v) => {
        
        const header = {
          ...formDetail,
          socode: form.getFieldValue("socode"),
          sodate: dayjs(form.getFieldValue("sodate")).format("YYYY-MM-DD"),
          cuscode: form.getFieldValue("cuscode"),
          remark: form.getFieldValue("remark"),
        };
        const detail = listDetail;
        
        const parm = { header, detail };
        const actions = soservice.update;
        
        actions(parm)
          .then((r) => {
            handleClose().then((r) => {
              message.success("แก้ไขสำเร็จ");
            });
          })
          .catch((err) => {
            message.error("แก้ไขไม่สำเร็จ");
            console.warn(err);
          });
      })
      .catch((err) => {
        Modal.error({
          title: "This is an error message",
          content: "Please enter require data",
        });
      });
  };

  useEffect(() => {
    const onload = () => {
      setLoading(true);
      soservice
        .get(socode)
        .then((res) => {
          let { header,detail } = res.data.data;
          setlistDetail(detail);
          form.setFieldValue("sodate", dayjs(new Date(header.sodate)));
          form.setFieldValue("socode", header.socode);
          form.setFieldValue("cuscode", header.cuscode);
          form.setFieldValue("cusname", header.cusname);

          // console.log(modalData, data)
          // console.log(header)
        })

        .catch((err) => {
          console.warn(err);
          const data = err?.response?.socode;
          message.error(data?.message || "ไม่พบข้อมูล");
          // setLoading(false);
        })
        .finally(() =>
          setTimeout(() => {
            setLoading(false);
          }, 400)
        );
    };

    if (!!openModal) {
      onload();
      // console.log("modal-packages")
    }

    return () => {};
  }, [openModal, form, socode]);

  useEffect(() => {
    if (listDetail) handleSummaryPrice();
  }, [listDetail]);

  const handleSummaryPrice = () => {
    const newData = [...listDetail];
    console.log(newData);
    const total_price = newData.reduce(
      (a, v) =>
        (a +=
          Number(v.qty || 0) *
            Number(v?.price || 0) *
            (1 - Number(v?.discount || 0) / 100) +
          Number(v.qty || 0) *
            Number(v?.price || 0) *
            (1 - Number(v?.discount || 0) / 100) *
            (v.vat / 100)),
      0
    );

    setFormDetail(() => ({
      ...formDetail,
      total_price,
    }));
    // console.log(formDetail)
  };

  const TitleTable = (
    <Flex className="width-100" align="center">
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start" align="center">
          <Typography.Title className="m-0 !text-zinc-800" level={3}>
            รายการสินค้า
          </Typography.Title>
        </Flex>
      </Col>
    </Flex>
  );

  return (
    <>
      <Modal
        open={openModal}
        title="แก้ไขตะกร้าสินค้า"
        okText="แก้ไข"
        onOk={handleConfirm}
        cancelText="ปิด"
        afterClose={() => handleClose()}
        onCancel={() => setOpenModel(false)}
        maskClosable={false}
        style={{ top: 20 }}
        width={1400}
        className="modal-cart"
        okButtonProps={{
          style: { backgroundColor: "#fa3f3f", color: "#FFFFFF" },
        }}
        cancelButtonProps={{
          style: { backgroundColor: "#212F3D", color: "#FFF" },
        }}
      >
        <Spin spinning={loading}>
          <Space
            direction="vertical"
            size="middle"
            style={{ display: "flex", position: "relative" }}
          >
            <Form form={form} layout="vertical" autoComplete="off">
              <Card style={{ backgroundColor: "#f0f0f0" }}>
                <Row
                  gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
                  className="m-0"
                >
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <Form.Item label="วันที่สั่งซื้อ" name="sodate">
                      <DatePicker
                        className="input-40"
                        allowClear={false}
                        // onChange={handleQuotDate}
                        format={dateFormat}
                        style={{ width: "100%" }}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4}>
                    <Form.Item label="รหัสใบสั่งซื้อสินค้า" name="socode">
                      <Input readOnly></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4}>
                    <Form.Item label="รหัสลูกค้า" name="cuscode">
                      <Input readOnly></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10}>
                    <Form.Item label="สั่งซื้อในนาม" name="cusname">
                      <Input readOnly></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card style={{ minHeight: "40vh" }}>
                <Table
                  bordered
                  title={() => TitleTable}
                  components={componentsEditable}
                  rowClassName={() => "editable-row"}
                  dataSource={listDetail}
                  columns={column}
                  rowKey="stcode"
                  pagination={false}
                  scroll={{ x: "max-content", y: 400 }}
                  size="small"
                  summary={() => {
                    return (
                      <>
                        {listDetail.length > 0 && (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={0}
                                colSpan={7}
                              ></Table.Summary.Cell>
                              <Table.Summary.Cell
                                index={4}
                                align="end"
                                className="!pe-4"
                              >
                                Grand Total
                              </Table.Summary.Cell>
                              <Table.Summary.Cell
                                className="!pe-4 text-end"
                                style={{ borderRigth: "0px solid" }}
                              >
                                <Typography.Text type="danger">
                                  {formatMoney(
                                    Number(formDetail?.total_price || 0),
                                    2
                                  )}
                                </Typography.Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell className="!pe-4 text-end">
                                Baht
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        )}
                      </>
                    );
                  }}
                />
              </Card>
              <Card style={{ backgroundColor: "#f0f0f0" }}>
                <Row gutter={[8, 8]} className="m-0">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item className="" name="remark" label="หมายเหตุ">
                      <Input.TextArea placeholder="Enter Remark" rows={2} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Space>
        </Spin>
      </Modal>
    </>
  );
}
