// import { Typography } from "antd";
import { comma } from "../../../utils/util";

export const pickuplistColumn = () => [
  {
    title: "No.",
    dataIndex: "ind",
    key: "ind",
    width: "1%",
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: "4%",
    align: "left",
  },
  {
    title: "ชื่อสินค้า",
    key: "stname",
    dataIndex: "stname",
    align: "left",
    render: (_, rec) => rec.stname,
  },
  {
    title: "จำนวนสั่งซื้อ",
    key: "qty",
    dataIndex: "qty",
    align: "right",
    className: "!pe-3",
    editable: true,
    required: true,
    type: 'number',
    render: (_, rec) => <>{comma(Number(rec?.qty || 0), 2, 2)}</>,
    width: "6%",
  },
  {
    title: "หน่วยสินค้า",
    dataIndex: "unit",
    key: "unit", 
      align: "right", 
      width: "5%",
  },
  {
    title: "หิ้ว",
    dataIndex: "del_count",
    key: "del_count", 
      align: "right", 
      width: "5%",
  },
  {
    title: "จำนวนส่งจริง",
    key: "del_qty",
    dataIndex: "del_qty",
    align: "right",
    className: "!pe-3",
    editable: true,
    required: true,
    type: 'number',
    render: (_, rec) => <>{comma(Number(rec?.del_qty || 0), 2, 2)}</>,
    width: "4%",
  },
  {
    title: "หน่วยสินค้า",
    dataIndex: "del_unit",
    key: "del_unit", 
      align: "right", 
      width: "7%",
  },
];