import React, { useState, useEffect } from "react";

import { Modal, Card, message, Input, InputNumber } from "antd";
import { Row, Col, Image, ConfigProvider, Form, Spin } from "antd";

import { initialvalue } from "./model.js";

import { BACKEND_URL_MAIN } from "../../../utils/util";
// import OptionService from "../../../service/Options.service";
import CartService from "../../../service/Cart.service";

// const opservice = OptionService();
const ctservice = CartService();

export default function ModalCart({ show, close, stcode, cuscode }) {
  const [form] = Form.useForm();

  const [listDetail, setlistDetail] = useState([initialvalue]);

  const [openModal, setOpenModel] = useState(show);
  const [loading, setLoading] = useState(true);
  const [totalprice, setTotalprice] = useState();

  /** handle logic component */
  const handleClose = () => {
    setTimeout(() => {
      close(false);
    }, 140);

    //setTimeout( () => close(false), 200 );
  };

  const setTotal = () => {
    // setlistDetail(...listDetail,{qty:form.getFieldValue("qty")});
    setTotalprice(form.getFieldValue("price") * form.getFieldValue("qty"));
  };

  const AdditemInCart = () => {
    // console.log(form.getFieldsValue())
    form.validateFields().then((v) => {
      const source = { ...listDetail, ...v };
      // console.log(source);
      const actions = ctservice.create(source);

      // actions
      //   .then(async (r) => {
      //     message.success("เพิ่มสินค้าลงตะกล้าสำเร็จ");
      //     handleClose();
      //     console.clear();
      //   })
      //   .catch((err) => {
      //     console.warn(err);
      //     const data = err?.response?.data;
      //     message.error(data?.message || "บันทึกไม่สำเร็จ");
      //   });

      actions
        .then(async (r) => {
          const { data } = r.data;
          // console.log(data.status)
          if (data.status) {
            message.success("เพิ่มสินค้าลงตะกร้าสำเร็จ");
            handleClose();
            // console.clear();
          } else {
            message.error("สินค้านี้มีในตะกร้าแล้ว");
          }
        })
        .catch((err) => {
          console.warn(err);
          const data = err?.response?.data;
          message.error(data?.message || "บันทึกไม่สำเร็จ");
        });
    });
  };

  useEffect(() => {
    const onload = () => {
      setLoading(true);
      ctservice
        .search({ stcode: stcode })
        .then(async (res) => {
          let { data } = res.data;
          setlistDetail(data);
          form.setFieldValue("qty", 1);
          form.setFieldValue("price", data[0].price);
          form.setFieldValue("stcode", data[0].stcode);
          form.setFieldValue("unit", data[0].unit);
          form.setFieldValue("cuscode", cuscode);
          // cuscode

          // setSelectqty(1);
          // setSelectprice();

          // console.log(listDetail)
        })
        .catch((err) => {
          console.warn(err);
          const data = err?.response?.data;
          message.error(data?.message || "error request");
          // setLoading(false);
        })
        .finally(() =>
          setTimeout(() => {
            setLoading(false);
          }, 400)
        );
    };

    if (!!openModal) {
      onload();
      // console.log("modal-packages")
    }

    return () => {};
  }, [openModal, form, stcode, cuscode]);

  return (
    <>
      <Modal
        title="รายละเอียดสินค้า"
        open={openModal}
        onOk={AdditemInCart}
        afterClose={() => handleClose()}
        onCancel={() => setOpenModel(false)}
        width={800}
        okText="เพิ่มสินค้า"
        cancelText="ปิด"
        okButtonProps={{
          style: { backgroundColor: "#fa3f3f", color: "#FFFFFF" },
        }}
        cancelButtonProps={{
          style: { backgroundColor: "#212F3D", color: "#FFF" },
        }}
      >
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" autoComplete="off">
            <div mode="horizontal">
              <ConfigProvider
                theme={{
                  token: {
                    colorBgBase: "#FFFFFF",
                  },
                }}
              >
                <Card
                  hoverable
                  style={{
                    marginTop: 5,
                  }}
                >
                  {listDetail.map((list, ind) => (
                    <Row gutter={[8, 8]} className="m-0">
                      <Col xs={24} sm={12} md={12} lg={12}>
                        <Card
                          style={{ padding: 10 }}
                          cover={
                            !!list.file[0] ? (
                              list.file.map((file) => (
                                <div key={file.uid}>
                                  <Image
                                    style={{ borderRadius: 10 }}
                                    preview={false}
                                    height={160}
                                    alt={`Image ${file.name}`}
                                    src={
                                      `${BACKEND_URL_MAIN}/uploads/` +
                                      file.file_name
                                    }
                                  />
                                </div>
                              ))
                            ) : (
                              <div key={list[ind]}>
                                <Image
                                  style={{ borderRadius: 10 }}
                                  preview={false}
                                  height={160}
                                  alt={`No Image`}
                                  src={`${BACKEND_URL_MAIN}/logo_nsf.jpg`}
                                />
                              </div>
                            )
                          }
                        >
                          {/* <Carousel
                      autoplay
                      autoplaySpeed={7000}
                      draggable={true}
                      arrows={true}
                    >
                      {list.file.map((file) => (
                        <div key={file.uid}>
                          <Image
                            width={250}
                            height={150}
                            preview={false}
                            src={file.file_name}
                            alt={`Image ${file.name}`}
                          />
                        </div>
                      ))}
                    </Carousel> */}
                        </Card>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12}>
                        <Card
                          title={list.stname}
                          description={list.price + " บาท"}
                        >
                          <Form.Item
                            label="จำนวน"
                            name="qty"
                            align="left"
                            rules={[
                              {
                                required: true,
                                message: "โปรดกรอกจำนวนที่จะสั่ง",
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="จำนวน"
                              style={{ width: "100%"}}
                              onChange={() => setTotal()}
                              suffix={form.getFieldValue("unit")}
                              min={1}
                              size="large"
                            />
                            
                          </Form.Item>
                          <Form.Item label="ราคา" name="price">
                            <InputNumber
                              placeholder="จำนวน"
                              style={{ width: "100%" }}
                              suffix="บาท"
                              readOnly
                            />
                          </Form.Item>
                          <Form.Item name="unit" style={{ display: "none" }}>
                            <Input type="hidden" disabled></Input>
                          </Form.Item>
                          <Form.Item name="stcode" style={{ display: "none" }}>
                            <Input type="hidden" disabled></Input>
                          </Form.Item>
                          <Form.Item name="cuscode" style={{ display: "none" }}>
                            <Input type="hidden" disabled></Input>
                          </Form.Item>
                        </Card>
                      </Col>
                    </Row>
                  ))}
                </Card>
                <Card style={{ marginTop: 30 }}>
                  <Row align="middle" gutter={[24, 0]}>
                    <Col align="center" span={6}>
                      {" "}
                      <h3 style={{ paddingTop: 17 }}>รวม</h3>
                    </Col>
                    <Col align="right" span={12}>
                      <h3 style={{ paddingTop: 17 }}>{totalprice}</h3>
                    </Col>
                    <Col align="right" span={6}>
                      <h3
                        style={{
                          paddingTop: 17,
                        }}
                      >
                        บาท
                      </h3>
                    </Col>
                  </Row>
                </Card>
              </ConfigProvider>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
