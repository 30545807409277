
import { EditableRow, EditableCell } from "../../table/TableEditAble";
import { comma,BACKEND_URL_MAIN } from '../../../utils/util';
import {
  Image,
} from "antd";
/** get items column */
export const accessColumn = ({ handleRemove }) => [  
  {
    key: "cart_id",
    dataIndex: "cart_id",
    align: "left",
    hidden: true,
    sorter: (a, b) => (a?.cart_id || "").localeCompare(b?.cart_id || ""),
  },
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    align: "center",
    width: 80,
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "รูปประกอบ",
    dataIndex: "file",
    key: "file",
    width: 120,
    align: "center",
    render: (im, rec) => 
      {
        const img = (!!rec.file_name ? `/uploads/` + rec.file_name : `/logo_nsf.jpg`
        );
        return <>
        <Image
      style={{ borderRadius: 10 }}
      preview={false}
      height={75}
      alt={`Image ${rec.file_name}`}
      src={`${BACKEND_URL_MAIN}` + img}
    />
    </>
    },
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: 120,
    align: "center",
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "purdetail",
    key: "purdetail",
    align: "left",
    render: (_, rec) => rec.stname,
  },
  {
    title: "ราคาต่อหน่วย",
    dataIndex: "price",
    key: "price",
    width: "15%",
    align: "center",
    className: "!pe-3",    
    render: (_, rec) => <>{comma(Number(rec?.price || 0), 2, 2)}</>,
  },
  {
    title: "หน่วยสินค้า",
    dataIndex: "unit",
    key: "unit",
    align: "center",
    width: "12%",
    render: (_, rec) => rec.unit,
  },
  {
    title: "จำนวนที่สั่งซื้อ",
    dataIndex: "qty",
    key: "qty",
    width: "12%",
    align: "right",
    className: "!pe-3",
    type: 'number',
    editable: true,
    required: true,
    render: (_, rec) => <>{comma(Number(rec?.qty || 0))}</>,
  },
  {
    title: "ตัวเลือก",
    align: "center",
    key: "operation",
    dataIndex: "operation",
    render: (_, record, idx) => handleRemove(record),
    width: '90px',
    fixed: 'right',
  },
];

/** export component for edit table */
export const componentsEditable = {
  body: { row: EditableRow, cell: EditableCell },
};

export const columnsParametersEditable = (handleEditCell, { handleRemove }) => {
  const col = accessColumn({ handleRemove });
  return col.map((col, ind) => {
    if (!col.editable) return col;


    return {
      ...col,
      onCell: (record) => {
        // console.log(record);
        let prop = {};
        // if (col?.type === 'number' && col.dataIndex === 'qty')
        //   prop = { max: Number((record?.qty) || 0) };
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          required: !!col?.required,
          type: col?.type || "input",
          handleEditCell,
          childProps: prop,
        }
      },
    };
  });
}

export const initialvalue = {    
  cuscode : null,
  stcode : null,
  stname : null,
  qty : 0,
  price : 0,
  unit: null,
  file: [],
}
