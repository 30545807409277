import { Button, Space } from "antd";
import "../../assets/styles/banks.css";
import {
  EditableRow,
  EditableCell,
} from "../../components/table/TableEditAble";
import { ToolOutlined } from "@ant-design/icons";
import { TagSalesOrderStatus } from "../../components/badge-and-tag";
/** export component for edit table */
export const componentsEditable = {
  body: { row: EditableRow, cell: EditableCell },
};

export const showdataso = ({ EditData }) => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    align: "center",
    width: "5%",
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "วันที่",
    dataIndex: "sodate",
    key: "sodate",
    width: "20%",
    align: "center",
  },
  {
    title: "เลขที่ใบขายสินค้า",
    dataIndex: "socode",
    key: "socode",
    width: "20%",
    align: "center",
  },
  {
    title: "สถานะ",
    dataIndex: "doc_status",
    key: "doc_status",
    width: "20%",
    align: "center",
    sorter: (a, b) => a.doc_status.localeCompare(b.doc_status),
    sortDirections: ["descend", "ascend"],
    render: (data) => <TagSalesOrderStatus result={data} />,
  },
  {
    title: "Action",
    key: "operation",
    fixed: "right",
    align: "center",
    width: "10%",
    render: (text, record) => (
      <Space>
        <Button
          icon={<ToolOutlined />}
          className="bn-primary-outline"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => EditData(record.socode)}
          size="small"
        >
          แก้ไข
        </Button>
      </Space>
    ),
  },
];

export const purchaseorderForm = {
  pocode: null,
  podate: null,
  supcode: null,
  supname: null,
  deldate: null,
  contact: null,
  supcontact: null,
  supaddress: null,
  tel: null,
  remark: null,
  total_price: 0,
  vat: 7,
  grand_total_price: 0,
};

export const purchaseorderDetailForm = {
  pocode: null,
  stcode: null,
  stname: null,
  discount: 0,
  qty: 0,
  price: 0,
  unit: null,
};
