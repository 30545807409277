import { requestService as api } from "./Request.service"  

const API_URL = {
  API_MANAGE: `/cart/manage.php`,
  API_SEARCH: `/cart/search.php`,
  API_CREATE: `/cart/create-so.php`,
  API_SEARCH_ByCus: `/cart/searchbycus.php`,
};



const CartService = () => { 
 
  const create = (parm = {}) => api.post(`${API_URL.API_MANAGE}`, parm);
  const createSO = (parm = {}) => api.post(`${API_URL.API_CREATE}`, parm);
  const update = (parm = {}, config = {}) => api.put(`${API_URL.API_MANAGE}`, parm, { cancle: true, ignoreLoading : true});
  const deleted = (code) => api.delete(`${API_URL.API_MANAGE}?code=${code}`);
  const search = (parm = {}) => api.post(`${API_URL.API_SEARCH}`, parm, { cancle: true, ignoreLoading : true});
  const searchByCustomer = (parm = {}, config = {}) => api.post(`${API_URL.API_SEARCH_ByCus}`, parm, {...config, cancle: true});

  return {
    create,
    createSO,
    update,
    deleted,
    search,
    searchByCustomer,

  };
};

export default CartService;
