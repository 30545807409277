import React from "react";
import { Route } from "react-router-dom";
import {
  Ecommerce,
  EcommerceAccess,
} from "../pages/ecommerce";
import { History, HistoryAccess } from "../pages/history";
export const HeaderEcommerce = (
  <>
    <Route path="/ecommerce/" exact element={<Ecommerce />}>
      <Route index element={<EcommerceAccess />} />
    </Route>
    <Route path="/history/" exact element={<History />}>
      <Route index element={<HistoryAccess />} />
    </Route>
  </>
);
